import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <>
      <div className={classes.copyRight}>
        <p>© 2021 SQUARE ENIX CO., LTD. All Rights Reserved.</p>
        <p> This Is For Learning Purpose.</p>
      </div>
    </>
  );
};

export default Footer;
